<template>
    <content-wrapper>
        <w-data-table
            outlined
            :loading="loading"
            :headers="headers"
            :items="collection.data"
            :filter-by="filterBy"
            :server-items-length="collection.total"
            searchable
            @change:options="fetchItems"
            @click:row="onRowClick"
        >
            <template #filters>
                <div>
                    <v-select
                        v-model="filterByDepartment"
                        :items="departments"
                        item-text="title"
                        item-value="uuid"
                        :placeholder="$trans('Department')"
                        dense
                        hide-details
                        clearable
                    >
                        <template #prepend>
                            <w-icon dense value="DEPARTMENT"/>
                        </template>
                    </v-select>
                </div>
            </template>
            <template #actions>
                <w-btn-action
                    :text="$trans('Add teacher')"
                    @click="onShowForm"
                />
            </template>
            <template #[`item.last_name`]="{item}">
                <member-full-name
                    :item="item"
                />
            </template>
            <template #[`item.departments`]="{item}">
                <div class="pa-2">
                    <div
                        v-for="department in item.departments"
                        :key="department.uuid"
                    >
                        {{ department.title }}
                    </div>
                </div>
            </template>
            <!-- actions -->
            <template #[`item.actions`]>
                <w-icon-next
                    color="primary"
                />
            </template>
        </w-data-table>
        <w-aside-drawer
            v-model="isAsideDrawer"
            :title="asideTitle"
        >
            <div class="pa-4">
                <teacher-form
                    :key="formKey"
                    :item="item"
                    @submit="onSubmit"
                />
            </div>
        </w-aside-drawer>
    </content-wrapper>
</template>

<script>
import { getTeachersCollection } from '@/apps/school/api/teachers'
import MemberFullName from '@/components/Member/MemberFullName'
import TeacherForm from '@apps/school/components/Teacher/TeacherForm'
import ContentWrapper from '@/components/ContentWrapper'
import hasCurrentSchoolPropertyMixin from '@apps/school/mixins/hasCurrentSchoolPropertyMixin'

export default {
    name: 'TeachersManage',
    components: {
        ContentWrapper,
        TeacherForm,
        MemberFullName
    },
    mixins: [hasCurrentSchoolPropertyMixin],
    computed: {
        asideTitle() {
            return this.$trans('Add teacher')
        },
        api() {
            return getTeachersCollection
        },
        headers() {
            return [
                {
                    text: this.$trans('Full name'),
                    value: 'last_name'
                },
                {
                    text: this.$trans('Email'),
                    value: 'email'
                },
                {
                    text: this.$trans('Phone'),
                    value: 'phone'
                },
                {
                    text: this.$trans('Department'),
                    value: 'departments'
                },
                {
                    value: 'actions',
                    sortable: false,
                    align: 'right'
                }
            ]
        },
        departments() {
            return this.$store.getters['school/departments']
        }
    },
    watch: {
        currentSchool(val, oldVal) {
            if(val && !oldVal) {
                this.fetchItems()
            }
        },
        filterByDepartment(uuid) {
            this.fetchItems({ department: uuid })
        }
    },
    data() {
        return {
            isAsideDrawer: false,
            loading: false,
            collection: {
                data: []
            },
            filterBy: ['last_name', 'first_name', 'middle_name', 'email', 'phone'],
            filterByDepartment: 'all',
            item: {},
            formKey: 0
        }
    },
    methods: {
        async fetchItems(payload) {
            if(!this.currentSchool) return

            this.loading = true

            payload = payload || {}

            payload.with = 'departments:uuid,school_uuid,title'
            payload.school = this.currentSchool.uuid
            payload.department = this.filterByDepartment

            await this.api(payload)
                .then(response => {
                    this.collection = response.data.data
                    this.loading = false
                })
        },
        onShowForm(item) {
            this.item = item || {}
            this.isAsideDrawer = true
            this.formKey++
        },
        onRowClick(item) {
            this.$router.push({
                name: 'manage.school.teachers.profile',
                params: { uuid: item.uuid }
            })
        },
        onSubmit(data) {
            this.item = {}
            this.isAsideDrawer = false
            this.loading = false
            this.fetchItems()
        }
    }
}
</script>

<style lang="scss">

</style>
